import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToAnchorDelayed();

    // Listen for Turbo frame load events
    document.addEventListener("turbo:frame-load", this.onFrameLoad.bind(this));
  }

  disconnect() {
    document.removeEventListener(
      "turbo:frame-load",
      this.onFrameLoad.bind(this),
    );
  }

  onFrameLoad(event) {
    if (event.target.id === "messages") {
      this.scrollToAnchorDelayed();
    }
  }

  scrollToAnchorDelayed() {
    // Delay scrolling to ensure content is fully loaded
    setTimeout(() => {
      this.scrollToAnchor();
    }, 50); // Adjust delay as needed (200ms works in most cases)
  }

  scrollToAnchor() {
    const anchor = document.getElementById("scroll-anchor");
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth" });
      console.log("Scrolled to anchor");
    }
  }
}
