import { Application } from "@hotwired/stimulus";
import Notification from "@stimulus-components/notification";
import Dropdown from "@stimulus-components/dropdown";
import Dialog from "@stimulus-components/dialog";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import CharacterCounter from "@stimulus-components/character-counter";
import AutoSubmit from "@stimulus-components/auto-submit";
import ReadMore from "@stimulus-components/read-more";
import Clipboard from "@stimulus-components/clipboard";
import ScrollTo from "@stimulus-components/scroll-to";
import Lightbox from "@stimulus-components/lightbox";
import RevealController from "@stimulus-components/reveal";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

application.register("notification", Notification);
application.register("dropdown", Dropdown);
application.register("dialog", Dialog);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("character-counter", CharacterCounter);
application.register("auto-submit", AutoSubmit);
application.register("read-more", ReadMore);
application.register("clipboard", Clipboard);
application.register("scroll-to", ScrollTo);
application.register("lightbox", Lightbox);
application.register("reveal", RevealController);

export { application };
