import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["country", "state", "city"];
  static values = { urlStates: String, urlCities: String };

  async changeCountry(event) {
    try {
      const params = new URLSearchParams();
      params.append("country", event.target.value);
      params.append("target", this.stateTarget.id);
      params.append("cities_target", this.cityTarget.id);

      await get(`${this.urlStatesValue}?${params}`, {
        responseKind: "turbo-stream",
      });
    } catch (error) {
      console.error("Network error:", error);
    }
  }

  async changeState(event) {
    if (this.stateTarget.value === event.target.value) return;

    const params = new URLSearchParams();
    params.append("country", this.countryTarget.value);
    params.append("state", event.target.value);
    params.append("target", this.cityTarget.id);

    await get(`${this.urlCitiesValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
