import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["spinner", "fileInput", "errorMessage"];

  connect() {
    this.allowedTypes = ["image/png", "image/jpeg", "image/webp"];
    this.maxSize = 10 * 1024 * 1024; // Default max size to 10 MB (for cover photos)

    // Check if custom max size is set via data attribute
    if (this.hasFileInputTarget && this.fileInputTarget.dataset.maxSize) {
      this.maxSize = parseInt(this.fileInputTarget.dataset.maxSize, 10);
    }
  }

  submit(event) {
    const fileInput = this.fileInputTarget;
    const file = fileInput.files[0];

    if (file) {
      // Validate file type
      if (!this.allowedTypes.includes(file.type)) {
        this.displayError(
          "Unsupported file type. Please upload a JPG, PNG, or WebP image.",
        );
        fileInput.value = ""; // Clear invalid input
        event.preventDefault();
        return false;
      }

      // Validate file size
      if (file.size > this.maxSize) {
        this.displayError(
          `File is too large. Maximum size is ${this.maxSize / (1024 * 1024)} MB.`,
        );
        fileInput.value = ""; // Clear invalid input
        event.preventDefault();
        return false;
      }
    }

    // Proceed with form submission
    const form = this.element.closest("form");
    if (form) {
      this.clearError();
      this.showSpinner();
      form.requestSubmit();
    }
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("hidden");
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add("hidden");
    }
  }

  displayError(message) {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.textContent = message;
      this.errorMessageTarget.classList.remove("hidden");
    }
  }

  clearError() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.textContent = "";
      this.errorMessageTarget.classList.add("hidden");
    }
  }
}
